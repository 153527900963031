<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <form class="form" @submit.prevent="submit">
        <h1 class="title">Bo'luv</h1>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>1-son xonasi:</label>
            <select v-model="form.number_one_length" class="form-control">
              <option
                v-for="(number, index) in generateNumber(1, 7, 1)"
                :key="index"
                :value="number"
              >
                {{ number }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>2-son xonasi:</label>
            <select v-model="form.number_two_length" class="form-control">
              <option
                v-for="(number, index) in generateNumber(1, 7, 1)"
                :key="index"
                :value="number"
              >
                {{ number }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Vaqti:</label>
            <input
              v-model="form.number_speed"
              type="number"
              class="form-control"
              min="1"
              max="3600"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Sonlar qatori:</label>
            <input
              v-model="form.number_row"
              type="number"
              class="form-control"
              min="1"
              max="300"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Misollar soni:</label>
            <input
              v-model="form.number_count"
              type="number"
              class="form-control"
              min="1"
              max="300"
            />
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-orange">Boshlash</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "settings",
  data: function () {
    return {
      form: {
        number_one_length: 1,
        number_two_length: 1,
        number_row: 3,
        number_count: 3,
        number_speed: 300,
      },
    };
  },
  methods: {
    submit: async function () {
      if (await this.$store.dispatch("divide/listPrepare", this.form)) {
        await this.$router.push({ name: "list_divide_start" });
      }
    },
    generateNumber: function (begin, end, step = 1) {
      let numbers = [];
      for (let i = begin; i <= end; i += step) {
        numbers.push(i);
      }
      return numbers;
    },
  },
};
</script>
